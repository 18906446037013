import React from 'react';
import * as PropTypes from 'prop-types';

import Button from '../Button';

import * as styles from './recirculation.module.scss';

const Recirculation = props => (
  <section className={styles.recirculation}>
    <div className={styles.inner}>
      <h2 className={styles.title}>{props.title}</h2>
      <div className={styles.cta}>
        <Button text={props.buttonText} url={props.buttonUrl} theme="cement" />
      </div>
    </div>
  </section>
);

Recirculation.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default Recirculation;
