import React from 'react';
import * as PropTypes from 'prop-types';
import TextUnderline from '../TextUnderline';

import * as styles from './pageHeader.module.scss';

const PageHeader = ({ headline, overline, headlineUnderline, url, underlineTheme, theme }) => (
  <header className={[styles.header, styles[theme]].join(' ')}>
    <div className={styles.content}>
      {overline && <h5 className={styles.overline}>{overline}</h5>}
      <h1 className={styles.title}>
        <TextUnderline
          headline={headline}
          headlineUnderline={headlineUnderline}
          url={url}
          underlineTheme={underlineTheme}
        />
      </h1>
    </div>
  </header>
);

PageHeader.propTypes = {
  overline: PropTypes.string,
  headline: PropTypes.string,
  headlineUnderline: PropTypes.string,
  underlineTheme: PropTypes.string,
  theme: PropTypes.string,
};

export default PageHeader;
