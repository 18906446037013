import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import Slider from 'react-slick';
import * as PropTypes from 'prop-types';

import Image from '../../Image';
import TextUnderline from '../../TextUnderline';

import * as counterStyles from '../../CarouselCounter/carouselCounter.module.scss';
import * as styles from './companyCarousel.module.scss';
import '../../../styles/vendor/slick-companies-slider.scss';

class CompanyCarousel extends Component {
  state = {
    activeSlide: 1,
  };

  createSlickSettings = () => {
    return {
      beforeChange: (current, next) => this.setState({ activeSlide: next + 1 }),
      infinite: true,
      arrows: true,
      speed: 500,
      variableWidth: true,
      slidesToScroll: 1,
      draggable: false,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            draggable: true,
          },
        },
      ],
    };
  };

  render() {
    const { activeSlide } = this.state;
    const { featuredCompanies } = this.props;
    const slickSettings = this.createSlickSettings();

    const caption = (company, forQuoteSlide) => (
      <figcaption className={['slick-founder-info', styles.founderInfo].join(' ')}>
        <strong>
          {forQuoteSlide && company.quoteAttribution ? company.quoteAttribution : company.founder}
        </strong>
        <br />
        {forQuoteSlide
          ? !company.quoteAttribution &&
            company.founderTitle && (
              <span>
                {company.founderTitle}
                {' of '}
              </span>
            )
          : company.founderTitle && (
              <span>
                {company.founderTitle}
                {' of '}
              </span>
            )}
        {company.name}
      </figcaption>
    );

    return (
      <div className={['slick-company-slider', styles.slider].join(' ')}>
        <div className={[counterStyles.counter, 'emerald'].join(' ')}>
          <span className={'counterCurrent'}>{activeSlide}</span>
          <span className={counterStyles.counterTotal}>{featuredCompanies.length}</span>
        </div>
        <Slider {...slickSettings}>
          {featuredCompanies &&
            featuredCompanies.map((company, i) => {
              return (
                <Link
                  key={i}
                  to={`/portfolio/${company.slug}`}
                  className={['slick-company-link', styles.slide].join(' ')}>
                  {company.showQuoteOnHomePage ? (
                    <figure>
                      <div className={['slick-illustrations', styles.illustrations].join(' ')}>
                        <div className={'slick-crop'}>
                          <div className={styles.quote}>
                            <h3 className={styles.quoteText}>
                              <TextUnderline
                                headline={company.quote ? company.quote.quote : ''}
                                headlineUnderline={company.quoteUnderline}
                                underlineTheme="canvasMint"
                              />
                            </h3>
                            <img
                              className={styles.spacer}
                              src="/images/illustration-spacer.png"
                              alt="Spacer"
                            />
                          </div>
                        </div>
                      </div>
                      {caption(company, true)}
                    </figure>
                  ) : (
                    <figure>
                      {company.founderHeadshot && (
                        <div className={['slick-illustrations', styles.illustrations].join(' ')}>
                          <div className={'slick-crop'}>
                            <Image className={styles.image} fluid={company.founderHeadshot.fluid} />
                          </div>
                        </div>
                      )}
                      {caption(company, false)}
                    </figure>
                  )}
                </Link>
              );
            })}
        </Slider>
      </div>
    );
  }
}

CompanyCarousel.propTypes = {
  featuredCompanies: PropTypes.array,
};

export default CompanyCarousel;

export const query = graphql`
  fragment PortfolioFeaturedCompanyQuery on ContentfulPortfolioPage {
    featuredCompanies {
      name
      slug
      founder
      founderTitle
      founderHeadshot {
        fluid {
          src
          srcSet
        }
      }
      quote {
        quote
      }
      quoteUnderline
      quoteAttribution
      showQuoteOnHomePage
    }
  }
`;
