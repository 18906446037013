import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Seo from '../components/SEO';
import Theme from '../components/Theme';
import CompanyList from '../components/CompanyList';
import CompanyCarousel from '../components/PortfolioPage/CompanyCarousel';
import Newsletter from '../components/Newsletter';
import PageHeader from '../components/PageHeader';
import Recirculation from '../components/Recirculation';
import GeneralLayout from '../layouts/index';

class PortfolioPageTemplate extends Component {
  render() {
    const { transition, data } = this.props;
    const { contentfulPortfolioPage, allContentfulCompany, contentfulNewsletter, site } = data;
    const { featuredCompanies } = contentfulPortfolioPage;

    return (
      <GeneralLayout>
        <Theme transition={transition}>
          <Seo
            siteData={site}
            seoData={contentfulPortfolioPage.seo}
            defaultTitle="Emergence - Portfolio Companies"
            pathName="/portfolio"
          />
          <PageHeader
            headline={contentfulPortfolioPage.headline}
            headlineUnderline={contentfulPortfolioPage.headlineUnderline}
            url="/about"
            underlineTheme="canvasTan"
            theme="light"
          />
          {featuredCompanies && <CompanyCarousel featuredCompanies={featuredCompanies} />}
          {allContentfulCompany && (
            <CompanyList companies={allContentfulCompany.edges} filters={true} />
          )}
          <Recirculation
            title="At Emergence, we don’t make bets. We make commitments."
            buttonText="Learn More"
            buttonUrl="/about"
          />
          <Newsletter content={contentfulNewsletter} />
        </Theme>
      </GeneralLayout>
    );
  }
}

PortfolioPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PortfolioPageTemplate;

export const pageQuery = graphql`
  query portfolioPageQuery {
    contentfulPortfolioPage {
      headline
      headlineUnderline
      ...PortfolioFeaturedCompanyQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    allContentfulCompany(
      sort: { fields: [name], order: ASC }
      filter: { hideFromPortfolioList: { ne: true }, slug: { ne: null } }
    ) {
      edges {
        node {
          ...PortfolioAllCompaniesQuery
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
